import bodyScrollLock from '../../inc/body-scroll-lock/bodyScrollLock.min';

/*
 * Disable and enable scrolling
 */
export function disableScroll( element ) {
  jQuery('body').addClass('block-scroll');
  bodyScrollLock.disableBodyScroll( element[0] );
}

export function enableScroll( element ) {
  jQuery('body').removeClass('block-scroll');
  bodyScrollLock.enableBodyScroll( element[0] );
}

jQuery(function($) {

  /*
   * Mobile Navigation
   */
  if ( $('.us_header__nav-trigger').length && $('.us_navigation').length ) {
    $(document).on('touchend click', '.us_header__nav-trigger', function(e) {
      e.preventDefault();

      var trigger      = $(this),
          nav          = $('.us_navigation'),
          header       = $('.us_header');

      if ( trigger.hasClass('is-active') ) {
        trigger.removeClass('is-active');
        nav.removeClass('is-active');
        header.removeClass('is-nav-open');

        enableScroll( nav );
      } else {
        trigger.addClass('is-active');
        nav.addClass('is-active');

        header.addClass('is-nav-open');

        disableScroll( nav );
      }
    });

    $(document).on('touchend click', '.us_navigation__close', function(e) {
      e.preventDefault();

      var trigger      = $('.us_header__nav-trigger'),
          nav          = $('.us_navigation'),
          header       = $('.us_header');

      if ( trigger.hasClass('is-active') ) {
        trigger.removeClass('is-active');
        nav.removeClass('is-active');
        header.removeClass('is-nav-open');

        enableScroll( nav );
      } else {
        trigger.addClass('is-active');
        nav.addClass('is-active');

        header.addClass('is-nav-open');

        disableScroll( nav );
      }
    });
  }

});